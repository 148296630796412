document.addEventListener('DOMContentLoaded', () => {
    var icons = document.querySelectorAll('.home-slider__icon');
    var bullets = document.querySelectorAll('.swiper-pagination-bullet');

    icons.forEach((icon, i) => { icons[i].onclick = () => bullets[i].click(); })
    sameHeight('.js-same-height-container', '.js-same-height-element');

})
var swiperHome = new Swiper(".home-slider__content.mySwiper", {
    direction: "vertical",
    pagination: {
        el: ".swiper-pagination",
        clickable: true
    },
    autoplay: {
        delay: 8000,
        disableOnInteraction: false,
    },
});


var swiper = new Swiper(".news-section-slider.mySwiper", {
    slidesPerView: 1,
    spaceBetween: 10,
    loop: true,
    navigation: {
        nextEl: ".news-section .swiper-button-next",
        prevEl: ".news-section .swiper-button-prev",
    },
    mousewheel: true,
    keyboard: true,
    breakpoints: {
        0: {
            slidesPerView: 1,
            spaceBetween: 26,
        },
        768: {
            slidesPerView: 2,
            spaceBetween: 26,
        },
        1200: {
            slidesPerView: 3,
            spaceBetween: 26,
        },
    },
    autoplay: {
        delay: 5000,
        disableOnInteraction: false,
    },
});

const sameHeight = (container, elementSelector) => {
    let calculating = false;

    const unsetHeight= () => {
        const elements = document.querySelectorAll(`${container} ${elementSelector}`);
        for (const element of elements) {
            element.style.height = 'auto';
        }
    }

    const setHeight = () => {
        var sliders = document.querySelectorAll(container);
        for (const slider of sliders) {
            var contents = slider.querySelectorAll(elementSelector);
            var maxHeight = 0;
            for (const content of contents){
                maxHeight = Math.max(maxHeight, content.offsetHeight);
            }
            for (const content of contents){
                content.style.height = maxHeight+'px';
            }
        }
    }

    unsetHeight();
    setHeight();
    window.addEventListener('resize', () => {
        if (!calculating) {
            calculating = true;
            unsetHeight();
            setHeight();
            calculating = false;
        }
    });
}

var swiper = new Swiper(".kunden-section-slider > .mySwiper", {
    slidesPerView: 6,
    spaceBetween: 10,
    navigation: {
        nextEl: ".kunden-section .swiper-button-next",
        prevEl: ".kunden-section .swiper-button-prev",
    },
    mousewheel: true,
    keyboard: true,
    breakpoints: {
        0: {
            slidesPerView: 2,
            spaceBetween: 26,
        },
        566: {
            slidesPerView: 3,
            spaceBetween: 26,
        },
        768: {
            slidesPerView: 4,
            spaceBetween: 26,
        },
        993: {
            slidesPerView: 5,
            spaceBetween: 26,
        },
        1200: {
            slidesPerView: 6,
            spaceBetween: 26,
        },
    },
    autoplay: {
        delay: 5000,
        disableOnInteraction: false,
    },
});

var swiper = new Swiper(".testimonials-section .mySwiper", {
    slidesPerView: 1,
    loop: true,
    navigation: {
        nextEl: ".testimonials-section .swiper-button-next",
        prevEl: ".testimonials-section .swiper-button-prev",
    },
    mousewheel: true,
    keyboard: true,
    autoplay: {
        delay: 5000,
        disableOnInteraction: false,
    },
});
// $bp--sm: 769px;
// $bp--md: 993px;
// $bp--lg: 1201px;
// $bp--xl: 1408px;